<template>
  <div class="container space-1">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 px-3 px-md-8 pr-lg-0 shadow" style="background-color:#EAF4FD">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6 space-lg-1 text-center text-lg-left">
          <h2 class="mb-3">{{ $t('TrustPage.BlogSection.Title') }}</h2>
          <p class="mb-4">{{ $t('TrustPage.BlogSection.Description') }}</p>
          <a id="ga-OW02-05-03" :href="$t('TrustPage.BlogSection.ButtonToURL')" class="btn btn-primary transition-3d-hover" target="_blank">{{ $t('TrustPage.BlogSection.StartButton') }}</a>
        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-cta.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
