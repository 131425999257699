<template>
  <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-trust-hero.svg`)})`}">
    <div class="container space-top-2 space-bottom-2 position-relative">
      <div class="row justify-content-lg-between align-items-center">
        <div class="col-md-7">
          <div class="mb-5 mb-md-0">
            <h1 class="mb-4">{{ $t('TrustPage.HeroSection.Title') }}</h1>
            <p class="mb-4">{{ $t('TrustPage.HeroSection.Description') }}</p>
            <a id="" href="https://hub.openapihub.com/login" class="btn btn-primary" target="_blank">{{ $t('TrustPage.HeroSection.StartButton') }}</a>
            <!-- <router-link id="ga-OW02-02-03" :to="{ path: `/${$route.params.lang}/pricing`}" class="btn btn-primary transition-3d-hover">{{ $t('TrustPage.HeroSection.StartButton') }}</router-link> -->
          </div>
        </div>

        <div class="col-md-5">
          <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-hero.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
</style>
